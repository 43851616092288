import Header from './../components/header/Header'
import "../components/header/style.css";
import Avatar from "./../img/wnd.jpg"

const Home = () => { return (
		<body className="header">
			<div className="header__wrapper">
				<div class="circular_image">
					<img src={Avatar}/>
				</div>
				<h1 className="header__title">
					<strong>
						Привет! Я <em>Ильдар</em>
					</strong>
					<br />создаю сайты под ключ
				</h1>
				<div className="header__text">
					<p>с креативным и современным подходом.</p>
				</div>
				<a href="/projects" className="btn">
					Мои проекты
				</a>
			</div>
		</body>
) }

export default Home;